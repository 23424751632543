import { useState } from "react";

export const continueRoute = () => {
  const storage = globalThis?.sessionStorage;
  const prevRoute = storage.getItem("prevPath");
  const currRoute = storage.getItem("currentPath");
  console.log("const storage = globalThis?.sessionStorage;", storage);
  if (
    !prevRoute ||
    (prevRoute && prevRoute == "null") ||
    prevRoute == currRoute
  ) {
    return "/devices";
  } else {
    return prevRoute;
  }
};

export const getLoginUrl = (url: string) => {
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";
  const loginUrl = `/auth/signin?prevUrl=${origin}${url}`;
  return loginUrl;
};

export default function useDebounce(func, delay) {
  const [timer, setTimer] = useState(null);
  return (...args) => {
    clearTimeout(timer);
    const _temp = setTimeout(() => {
      func(...args);
    }, delay);
    setTimer(_temp);
  };
}

export const getFormattedDate = (date: Date) => {
  const d = date ? date : new Date();
  return {
    year: d?.getFullYear(),
    month: d?.getMonth() + 1,
    day: d?.getDate(),
  };
};

export const getFormattedTimeStamp = (values: string) => {
  const dateTime = new Date(values);
  const formattedDate = dateTime.toLocaleString("en-US", {
    timeStyle: "short",
    dateStyle: "medium",
  });
  return formattedDate;
};

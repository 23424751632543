import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Loader from "../../../public/assets/svg/LoaderIcon";
import { useStore } from "../../storeManager/stores";
import { observer } from "mobx-react";

const authRoute = (Component: any, title?: string, redirect = true) => {
  const AuthenticationRoute = (props: any) => {
    const { isUserPresent, isLoading } = useStore().authStore;
    const router = useRouter();

    const showLoading = (isLoading || !isUserPresent) && redirect;

    useEffect(() => {
      if (!isLoading && !isUserPresent && redirect) {
        router.push("/auth/signin");
      }
    }, [isUserPresent, isLoading, router]);

    if (showLoading) {
      return (
        <div className="dark:bg-dark h-screen flex justify-center items-center">
          <Loader className="w-10 h-10 text-primary-500 inline mr-3 animate-spin" />
        </div>
      );
    }

    return (
      <>
        <Head>
          <title>{title}</title>
          <link
            rel="icon"
            href="/mecha_small_logo.svg"
            sizes="any"
            type="image/svg+xml"
          />
        </Head>
        <Component {...props} />
      </>
    );
  };

  return observer(AuthenticationRoute);
};
export default authRoute;

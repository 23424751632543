import React from "react";

const AccountSettingsSvg = (props: React.ComponentProps<"svg">) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 12.5H7.5C5.01472 12.5 3 14.5147 3 17C3 18.1045 3.89543 19 5 19H10M14 5.99997C14 7.65682 12.6569 8.99997 11 8.99997C9.34315 8.99997 8 7.65682 8 5.99997C8 4.34312 9.34315 2.99997 11 2.99997C12.6569 2.99997 14 4.34312 14 5.99997ZM17.5 16C17.5 16.8284 16.8284 17.5 16 17.5C15.1716 17.5 14.5 16.8284 14.5 16C14.5 15.1716 15.1716 14.5 16 14.5C16.8284 14.5 17.5 15.1716 17.5 16ZM10.326 14.1841C10.5876 13.3364 11.0368 12.545 11.6555 11.8782L12.4673 12.3469C13.4673 12.9243 14.7173 12.2026 14.7173 11.0479V10.1105C15.6041 9.90811 16.514 9.91472 17.379 10.1121V11.0479C17.379 12.2026 18.629 12.9243 19.629 12.3469L20.4406 11.8784C20.7387 12.2005 21.0058 12.5606 21.2345 12.9567C21.4632 13.3528 21.6415 13.7642 21.7714 14.1834L20.9598 14.652C19.9598 15.2294 19.9598 16.6727 20.9598 17.2501L21.7703 17.718C21.5087 18.5658 21.0595 19.3571 20.4408 20.0239L19.629 19.5552C18.629 18.9779 17.379 19.6996 17.379 20.8543V21.7917C16.4922 21.9941 15.5823 21.9874 14.7173 21.7901V20.8542C14.7173 19.6995 13.4673 18.9778 12.4673 19.5552L11.6557 20.0238C11.3576 19.7017 11.0905 19.3416 10.8618 18.9454C10.6331 18.5493 10.4548 18.1379 10.3249 17.7187L11.1364 17.2501C12.1364 16.6728 12.1364 15.2294 11.1364 14.652L10.326 14.1841Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default AccountSettingsSvg;
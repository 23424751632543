import Head from "next/head";
import Navbar from "../Layout/Navbar";

interface Props {
  logoutUrl: string;
}

// tslint:disable-next-line: no-any
const WithLayout = (
  ComposedComponent: any,
  title?: string,
  pageName?: string,
) => {
  const withLayout = (props: Props) => {
    return (
      <>
        <Head>
          <title>{title + " | " + (pageName || "Mecha Console")}</title>
          <link
            rel="icon"
            href="/mecha_small_logo.svg"
            sizes="any"
            type="image/svg+xml"
          />
        </Head>
        <div className={`font-space-grotesk bg-storm-dust-110 dark:bg-dark`}>
          {pageName?.toLowerCase() !== "terminal" && (
            <Navbar logoutUrl={props.logoutUrl} title={title} />
          )}
          <div className="w-full 2xl:w-[1300px] 2xl:mx-auto ">
            <ComposedComponent {...props} />
          </div>
        </div>
      </>
    );
  };

  return withLayout;
};

export default WithLayout;
